import {
  addDoc,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  setDoc,
  doc,
  getDoc,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  QueryLimitConstraint,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'

interface GetListProps {
  companyId: string
  setSupplierList: (stockList: Supplier[] | undefined) => void
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
  startAfter?: QueryStartAtConstraint[]
  limit?: QueryLimitConstraint[]
}
export const getList = async ({
  companyId,
  setSupplierList,
  setLoadingList,
  whereList,
  orderByList,
  startAfter,
  limit,
}: GetListProps) => {
  setLoadingList(true)

  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SUPPLIER,
    ),
    ...(whereList || []),
    ...(orderByList || []),
    ...(startAfter || []),
    ...(limit || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const supplierList: Supplier[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as Supplier
        supplierList.push(dataItem)
      })
      setLoadingList(false)
      setSupplierList(supplierList)
    },
    () => {
      console.log('Supplier - getList() - error')
      setLoadingList(false)
    },
  )
}

interface GetOneProps {
  companyId: string
  supplierId: string
}
export const getOne = async ({
  companyId,
  supplierId,
}: GetOneProps): Promise<Supplier | undefined> => {
  const docRef = doc(
    firestore,
    FirebaseCollection.BASE_COMPANY,
    companyId,
    FirebaseCollection.BASE_SUPPLIER,
    supplierId,
  )
  const docSnap = await getDoc(docRef)

  try {
    if (docSnap.exists()) {
      return {
        id: supplierId,
        ...docSnap.data(),
      } as Supplier
    }
  } catch (error) {
    return undefined
  }

  return undefined
}

interface SaveProps {
  companyId: string
  values: Partial<Supplier>
}
export const save = async ({ companyId, values }: SaveProps) =>
  await addDoc(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SUPPLIER,
    ),
    {
      ...values,
      nameSearch: `${values.name || ''} ${values.id || ''}`.toUpperCase(),
      emailSearch: `${values.email || ''} ${values.id || ''}`.toUpperCase(),
      createdAt: new Date().getTime(),
      dateCreatedAt: new Date().setHours(0, 0, 0, 0),
      updatedAt: new Date().getTime(),
    },
  ).then(resp => resp.id)

interface updateProps {
  companyId: string
  supplierId: string
  values: Partial<Supplier>
}
export const update = async ({
  companyId,
  supplierId,
  values,
}: updateProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SUPPLIER,
      supplierId,
    ),
    {
      ...values,
      nameSearch: `${values.name || ''} ${values.id || ''}`.toUpperCase(),
      emailSearch: `${values.email || ''} ${values.id || ''}`.toUpperCase(),
      updatedAt: new Date().getTime(),
    },
    { merge: true },
  )
}

interface RemoveProps {
  companyId: string
  supplierId: string
}
export const remove = async ({ companyId, supplierId }: RemoveProps) => {
  await deleteDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_SUPPLIER,
      supplierId,
    ),
  )
}

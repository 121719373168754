import { createGlobalStyle } from 'styled-components'

const GlobalStyle: any = createGlobalStyle<{ $whiteColor?: boolean }>`
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body { 
    /* background-color: #f9fafb; */
  }
`

export default GlobalStyle

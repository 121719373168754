import React from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import LottieNoContent from "../../assets/lottie/no-content.json";
import LottieNoSearchYet from "../../assets/lottie/no-search-yet.json";

type LottieType = 'NO_CONTENT' | 'NO_SEARCH_YET'

interface NoContentProps {
  title?: string;
  subtitle?: string;
  size?: number;
  type?: LottieType
}
export const NoContent = ({
  title = "No data",
  subtitle = "All saved records will be listed here",
  size = 200,
  type = 'NO_CONTENT'
}: NoContentProps) => (
  <Stack>

    {type === 'NO_CONTENT' && (
      <Player
        autoplay
        src={LottieNoContent}
        keepLastFrame
        speed={0.8}
        style={{
          height: `${size}px`,
          width: "100%",
          padding: 0
        }}
      />
    )}

    {type === 'NO_SEARCH_YET' && (
      <Player
        autoplay
        src={LottieNoSearchYet}
        keepLastFrame
        speed={0.8}
        style={{
          height: `${size}px`,
          width: "100%",
          padding: 0
        }}
      />
    )}

    <Typography textAlign="center" typography='h6' sx={{ marginBottom: 0.5 }}>
      {title}
    </Typography>

    {subtitle && (
      <Typography color={grey[500]} textAlign="center" variant='body2'>
        {subtitle}
      </Typography>
    )}
  </Stack>
);

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TbArrowLeft, TbPhotoCheck, TbPhotoPlus, TbTrash } from 'react-icons/tb'

import { where } from 'firebase/firestore'
import { Form, FormikProvider, useFormik } from 'formik'
import moment from 'moment'

import {
  BoxShadow,
  Breadcrumb,
  CircularProgress,
  DialogConfirm,
  FormikTextField,
  PhotoUpload,
} from '@/components'
import { Header as HeaderUser } from '@/components/header'
import { StatusServiceFlow, StatusServiceFlowColors } from '@/components/status-service-flow'
import { EnumServiceStatus } from '@/constants/service'
import { useGlobalState } from '@/context'
import { useStorage } from '@/hooks/useStorage'
import { useStorageFiles } from '@/hooks/useStorageFiles'
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style'
import { getPathFileCompanyService } from '@/utils/storage'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Avatar,
  Box,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'

import {
  validationWorkFinishToDelivered,
  validationApprovedToStartWork,
  validationStartWorkToWorkFinish,
} from './validationSchema'

const PHOTO_NAME = 'service_image_delivered'
const PHOTO_NAME_ADD = 'service_image_add'

interface HeaderProps {
  onRightIcon: () => void
  title?: string
  backgroundColor?: string
}
const Header = ({ title, backgroundColor, onRightIcon }: HeaderProps) => (
  <AppBar position='static'>
    <Toolbar sx={{ ...(backgroundColor ? { backgroundColor } : {}) }}>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        onClick={onRightIcon}
      >
        <TbArrowLeft />
      </IconButton>
      <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
)

interface InitialValuesApprovedToStartWork
  extends Pick<Service, 'status' | 'assignServiceWorkerStartWork'> {
  confirmedChange?: false
}
const INITIAL_VALUES_APPROVED_TO_START_WORK: InitialValuesApprovedToStartWork = {
  status: 'START_WORK',
  assignServiceWorkerStartWork: undefined,
  confirmedChange: false,
}

interface InitialValuesStartWorkToWorkFinish
  extends Pick<Service, 'status'> {
  confirmedChange?: false
}
const INITIAL_VALUES_START_WORK_TO_WORK_FINISH: InitialValuesStartWorkToWorkFinish = {
  status: 'WORK_FINISH',
  confirmedChange: false,
}

interface InitialValuesApprovedToDelivered extends Pick<Service, 'status' | 'assignServiceWorkerDelivered'> {
  hasImage: boolean
  confirmedChange: false,
}
const INITIAL_VALUES_WORK_FINISH_TO_DELIVERED: InitialValuesApprovedToDelivered = {
  status: 'DELIVERED',
  assignServiceWorkerDelivered: '',
  hasImage: false,
  confirmedChange: false,
}

interface showDialoadChangeStatus {
  tile: string
  currentStatus: ServiceStatusType
}

export const MobileBucket = () => {
  const [{ user, company }, dispatch] = useGlobalState()
  const { pathname } = location
  const inputFileRef = useRef<HTMLInputElement>(null)
  const inputFileDeliverRef = useRef<HTMLInputElement>(null)
  const [serviceList, setServiceList] = useState<Service[] | undefined>(
    undefined,
  )
  const companyId = useMemo(() => company?.id || '', [])
  const [step, setStep] = useState<number>(1)
  const [serviceStepSelected, setServiceStepSelected] = useState<
    Service | undefined
  >(undefined)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const { startUpload: startUploadFiles } = useStorageFiles()
  const { startUpload: startUploadSingle } = useStorage()
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [fileToUploadDeliver, setFileToUploadDeliver] = useState<File | undefined>(undefined)
  const [loadingSaveImage, setLoadingSaveImage] = useState<boolean>(false)
  const [hasNewImageSaved, setHasNewImageSaved] = useState<boolean>(false)
  const [fileListToUpload, setFileListToUpload] = useState<File[]>([])
  const [fileFromStorageSelecet, setFileFromStorageSelecet] = useState<
    FileFromStorage | undefined
  >(undefined)
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false)

  const ServiceListApproved = useMemo(
    () => serviceList?.filter(service => ['APPROVED'].includes(service.status)),
    [serviceList],
  )

  const ServiceListStartWork = useMemo(
    () => serviceList?.filter(service => ['START_WORK'].includes(service.status)),
    [serviceList],
  )

  const ServiceListWorkFinish = useMemo(
    () => serviceList?.filter(service => ['WORK_FINISH'].includes(service.status)),
    [serviceList],
  )

  const [showDialoadChangeStatus, setShowDialoadChangeStatus] = useState<
    showDialoadChangeStatus | undefined
  >(undefined)
  const [fileFromStorageList, setFileFromStorageList] = useState<
    FileFromStorage[] | undefined
  >(undefined)

  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'MobileBucket',
      title: 'Service Bucket',
      isCurrentPage: true,
    },
  ]

  const hideBreadcrumb = useMemo(() => {
    const urlPath = pathname.substring(1)
    return `/${urlPath}` === RoutesNames.MOBILE_BUCKET
  }, [])

  const isRoleMechanic = useMemo(() => {
    let isMechanic = false

    if (
      user?.userConfig?.roles.includes('MECHANIC_HYDRAULIC') ||
      user?.userConfig?.roles.includes('MECHANIC_BUCKET')
    ) {
      isMechanic = true
    }

    return isMechanic
  }, [user])

  const resetFormApprovedToStartWork = () => {
    formikApprovedToStartWork.resetForm()
  }

  const resetFormStartWorkToWorkFinish = () => {
    formikStartWorkToWorkFinish.resetForm()
  }

  const resetFormWorkFinishToDelivered = () => {
    formikWorkFinishToDelivered.resetForm()
  }

  const resetFormAll = () => {
    resetFormApprovedToStartWork()
    resetFormStartWorkToWorkFinish()
    resetFormWorkFinishToDelivered()

    setServiceStepSelected(undefined)
    setLoadingSave(false)
    setLoadingList(false)
    setStep(1)
    setFileToUpload(undefined)
    setFileToUploadDeliver(undefined)
    setLoadingSaveImage(false)
    setHasNewImageSaved(false)
    setFileListToUpload([])
    setLoadingFiles(false)
    setShowDialoadChangeStatus(undefined)
    setFileFromStorageList(undefined)
  }

  const formikApprovedToStartWork = useFormik({
    initialValues: INITIAL_VALUES_APPROVED_TO_START_WORK,
    validationSchema: validationApprovedToStartWork,
    onSubmit: async values => {
      if (!serviceStepSelected?.id) return

      if (!values.confirmedChange) {
        setShowDialoadChangeStatus({
          tile: `Do you really want to change the status to "${EnumServiceStatus.START_WORK}"?`,
          currentStatus: 'APPROVED',
        })
        return
      }

      setLoadingSave(true)

      await ApiService.Mobile.assignWorkerApprovedToStartWork({
        companyId,
        serviceId: serviceStepSelected.id,
        values,
      })
        .then(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Status changed' },
          })
        })
        .catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on change status', alertSeverity: 'error' },
          })
        })
        .finally(() => {
          resetFormAll()
        })
    },
  })

  const formikStartWorkToWorkFinish = useFormik({
    initialValues: INITIAL_VALUES_START_WORK_TO_WORK_FINISH,
    validationSchema: validationStartWorkToWorkFinish,
    onSubmit: async values => {
      if (!serviceStepSelected?.id) return

      if (!values.confirmedChange) {
        setShowDialoadChangeStatus({
          tile: `Do you really want to change the status to "${EnumServiceStatus.WORK_FINISH}"?`,
          currentStatus: 'START_WORK',
        })
        return
      }

      setLoadingSave(true)

      await ApiService.Mobile.assignWorkerStartWorkToWorkFinish({
        companyId,
        serviceId: serviceStepSelected.id,
        values,
      })
        .then(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Status changed' },
          })
        })
        .catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on change status', alertSeverity: 'error' },
          })
        })
        .finally(() => {
          resetFormAll()
        })
    },
  })

  const formikWorkFinishToDelivered = useFormik({
    initialValues: INITIAL_VALUES_WORK_FINISH_TO_DELIVERED,
    validationSchema: validationWorkFinishToDelivered,
    onSubmit: async values => {
      if (!serviceStepSelected?.id) return

      if (!values.confirmedChange) {
        setShowDialoadChangeStatus({
          tile: `Do you really want to change the status to "${EnumServiceStatus.DELIVERED}"?`,
          currentStatus: 'WORK_FINISH',
        })
        return
      }

      const data = {
        status: values.status,
        assignServiceWorkerDelivered: values.assignServiceWorkerDelivered,
      }

      setLoadingSave(true)

      startUploadFiles({
        files: fileListToUpload,
        filePath: getPathFileCompanyService(companyId, serviceStepSelected.id),
        fileName: PHOTO_NAME,
        async onSuccess() {

          await ApiService.Mobile.assignWorkerWorkFinishToDelivered({
            companyId,
            serviceId: serviceStepSelected.id,
            values: data,
          })
            .then(() => {
              dispatch({
                type: 'SET_SNACKBAR',
                data: { message: 'Status changed' },
              })
            })
            .catch(() => {
              dispatch({
                type: 'SET_SNACKBAR',
                data: {
                  message: 'Error on change status',
                  alertSeverity: 'error',
                },
              })
            })
            .finally(() => {
              resetFormAll()
            })
        },
        onError() {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error saving image' },
          })
          setLoadingSave(false)
        },
      })
    },
  })

  const RenderStepHome = () => {
    interface RenderCardProps extends React.PropsWithChildren {
      status: ServiceStatusType
      title: string
      count: number
      isFullHeight?: boolean
    }
    const RenderCard = ({
      status,
      title,
      count,
      isFullHeight,
      children,
    }: RenderCardProps) => {
      const backgroundColor = StatusServiceFlowColors[status].color[500]
      const backgroundColorBullet = StatusServiceFlowColors[status].color[900]
      const borderColor = StatusServiceFlowColors[status].color[500]
      const color = '#FFF'

      return (
        <Stack
          sx={{
            display: 'flex',
            flex: 1,
            ...(isFullHeight
              ? { height: '100%' }
              : { minHeight: 'calc(100vh - 70px)' }),
            border: `1px solid ${borderColor}`,
            borderRadius: 2,
            overflow: 'hidden',
            backgroundColor: grey[100],
          }}
        >
          <Stack
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            paddingY={1}
            sx={{
              paddingX: 2,
              backgroundColor,
            }}
          >
            <Typography variant='subtitle1' color={color}>
              {title}
            </Typography>
            <Stack
              display='flex'
              justifyContent='center'
              alignItems='center'
              padding={1}
              borderRadius={100}
              width={40}
              height={40}
              sx={{
                paddingX: 2,
                backgroundColor: backgroundColorBullet,
              }}
            >
              <Typography variant='h6' color={color}>
                {count}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            height='calc(100vh - 130px)'
            paddingY={3}
            paddingX={1}
            paddingBottom={0}
            sx={{
              display: 'block',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            {loadingList ? (
              <Stack
                display='flex'
                flex={1}
                height='100%'
                justifyContent='center'
                alignItems='center'
              >
                <CircularProgress />
              </Stack>
            ) : (
              children
            )}
          </Stack>
        </Stack>
      )
    }

    const renderCardStatus = (service: Service) => {
      const {
        id,
        status,
        title,
        description,
        assignServiceWorkerStartWork,
        clientFullname,
      } = service

      return (
        <BoxShadow>
          <Stack paddingY={2} paddingX={1} onClick={() => {
            setServiceStepSelected(service)
            setStep(2)
          }}>

            <Typography variant='caption' fontWeight='bold'>KEY: {id.substring(0, 6).toUpperCase()}</Typography>
            <Box mb={1} />
            <Typography
              variant='body2'
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
              }}
            >
              {title}
            </Typography>
            <Box mb={1} />
            <Typography variant='caption' textOverflow='ellipsis' noWrap  >
              {description}
            </Typography>
            <Stack
              sx={{ borderTop: `1px dashed ${grey[200]}`, mt: 1, pt: 1 }}
            >
              <Typography variant='caption' textOverflow='ellipsis' noWrap>
                Client: <Typography variant='caption' fontWeight='bold'>
                  {clientFullname}
                </Typography>
              </Typography>

              {assignServiceWorkerStartWork && (
                <>
                  <Box mb={0.5} />
                  <Typography variant='caption'>
                    Start Work:{' '}
                    <Typography variant='caption' fontWeight='bold'>
                      {assignServiceWorkerStartWork}
                    </Typography>
                  </Typography>
                </>
              )}

              {status === 'APPROVED' && (
                <>
                  <Box mb={1} />
                  <StatusServiceFlow serviceStatusType='APPROVED' title={EnumServiceStatus.APPROVED} />
                </>
              )}

              {status === 'START_WORK' && (
                <>
                  <Box mb={1} />
                  <StatusServiceFlow serviceStatusType='START_WORK' title='Work in progress' />
                </>
              )}

              {status === 'WORK_FINISH' && (
                <>
                  <Box mb={1} />
                  <StatusServiceFlow serviceStatusType='WORK_FINISH' title='Ready to be delivered' />
                </>
              )}
            </Stack>
          </Stack>
        </BoxShadow>
      )
    }

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <RenderCard
          status='APPROVED'
          title={EnumServiceStatus.APPROVED}
          count={ServiceListApproved?.length || 0}
        >
          {ServiceListApproved?.map(service => (
            <div key={service.id}>
              {renderCardStatus(service)}
              <Box mb={3} />
            </div>
          ))}
        </RenderCard>

        <Box sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 } }} />

        <RenderCard
          status='START_WORK'
          title={EnumServiceStatus.START_WORK}
          count={ServiceListStartWork?.length || 0}
        >
          {ServiceListStartWork?.map(service => (
            <div key={service.id}>
              {renderCardStatus(service)}
              <Box mb={3} />
            </div>
          ))}
        </RenderCard>

        <Box sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 } }} />

        <RenderCard
          status='WORK_FINISH'
          title={EnumServiceStatus.WORK_FINISH}
          count={ServiceListWorkFinish?.length || 0}
        >
          {ServiceListWorkFinish?.map(service => (
            <div key={service.id}>
              {renderCardStatus(service)}
              <Box mb={3} />
            </div>
          ))}
        </RenderCard>
      </Box>
    )
  }

  const RenderFileListToUpload = () => {
    const handleRemoveFile = (index: number) => {
      const arrayFiltered = [] as File[]
      fileListToUpload.forEach((item, idx) => {
        if (idx !== index) arrayFiltered.push(item)
      })
      setFileListToUpload(arrayFiltered)
      if (arrayFiltered.length === 0) {
        formikWorkFinishToDelivered.setFieldValue('hasImage', false)
      }
    }

    if (fileListToUpload.length === 0) {
      return (
        <Stack
          display='flex'
          flexDirection='row'
          flex={1}
          justifyContent='center'
        >
          <Typography variant='subtitle1'>There is no image choosed</Typography>
        </Stack>
      )
    }

    return (
      <Stack
        display='flex'
        flexDirection='row'
        sx={{
          overflowX: 'auto',
          width: '100%',
          paddingY: 3,
        }}
      >
        {fileListToUpload.map((file, index) => (
          <Stack key={file.name} position='relative'>
            <Avatar
              sx={{
                height: 60,
                width: 60,
                marginRight: 2,
              }}
              src={URL.createObjectURL(file)}
            />

            <Stack position='absolute' right={-5} top={-10}>
              <IconButton
                onClick={() => {
                  handleRemoveFile(index)
                }}
              >
                <TbTrash color={red[500]} />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </Stack>
    )
  }

  const RenderFileListFromBucket = () => (
    <Stack
      display='flex'
      flexDirection='row'
      height={60}
    >
      {loadingFiles && (
        <Stack width='100%' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Stack>
      )}

      {!loadingFiles && (
        <Stack mr={2}>
          <PhotoUpload
            inputFileRef={inputFileRef}
            fileToUpload={fileToUpload}
            setFileToUpload={(files) => {
              if (!files) return
              setFileToUpload(files[0])
            }}
            showTextAllowFile={false}
            avatarSize={46}
            handleDeletedImage={() => {
              setFileToUpload(undefined)
            }}
            addMenuList={[{
              action: 'SAVE',
              title: 'Save image',
              icon: loadingSaveImage ? <CircularProgress size={20} /> : <TbPhotoCheck size={20} color={grey[900]} />,
              onClick: () => {

                if (!serviceStepSelected) return
                setLoadingSaveImage(true)
                startUploadSingle({
                  file: fileToUpload,
                  filePath: getPathFileCompanyService(companyId, serviceStepSelected.id),
                  fileName: `${PHOTO_NAME_ADD}_${new Date().getTime()}`,
                  onSuccess(photoURL) {
                    dispatch({
                      type: 'SET_SNACKBAR',
                      data: { message: 'Image saved' }
                    })
                    setLoadingSaveImage(false)
                    setHasNewImageSaved(true)
                    setFileToUpload(undefined)
                  },
                  onError() {
                    dispatch({
                      type: 'SET_SNACKBAR',
                      data: { message: 'Error saving image', alertSeverity: 'error' }
                    })
                    setLoadingSaveImage(false)
                  }
                })
              }
            }]}
          >
            <Stack>
              <TbPhotoPlus size={32} />
            </Stack>
          </PhotoUpload>
        </Stack>
      )}

      {!loadingFiles &&
        fileFromStorageList?.map((file, index) => {
          const isTheLast = fileFromStorageList.length === index + 1
          return (
            <Avatar
              key={file.name}
              sx={{
                height: 60,
                width: 60,
                cursor: 'pointer',
                marginRight: isTheLast ? 0 : 2,
                backgroundColor: grey[500],
                ...(fileFromStorageSelecet?.name === file.name
                  ? { border: `1px solid ${theme.palette.primary.main}` }
                  : {}),
              }}
              onClick={() => {
                window.open(file.url, '_newtab')
                setFileFromStorageSelecet(file)
              }}
              src={file.url}
            />
          )
        })}
    </Stack>
  )

  const RenderStepFlow = () => {
    if (!serviceStepSelected) return null

    const { id, status, title, description, createdAt, updatedAt } =
      serviceStepSelected

    const RenderStepApproved = () => (
      <FormikProvider value={formikApprovedToStartWork}>
        <Form>

          <Typography variant='subtitle1'>Assign service to worker</Typography>
          <Box mb={1} />
          <FormikTextField name='assignServiceWorkerStartWork' label='Worker name' />

          <Box mb={4} />

          <LoadingButton
            variant='contained'
            color='btnPrimaryFill'
            disableElevation
            fullWidth
            size='large'
            loading={loadingSave}
            type='submit'
          >
            Send to {`"${EnumServiceStatus.START_WORK}"`}
          </LoadingButton>

        </Form>
      </FormikProvider>
    )

    const RenderStepStarWork = () => (
      <Stack>
        <Typography variant='h6'>
          Change status
        </Typography>
        <Box mb={4} />
        <LoadingButton
          variant='contained'
          color='btnPrimaryFill'
          disableElevation
          fullWidth
          size='large'
          loading={loadingSave}
          onClick={() => {
            formikStartWorkToWorkFinish.submitForm()
          }}
        >
          Send to {`"${EnumServiceStatus.WORK_FINISH}"`}
        </LoadingButton>
      </Stack>
    )

    const RenderStepWorkFinish = () => (
      <FormikProvider value={formikWorkFinishToDelivered}>
        <Form>
          <Box mt={3} />
          <FormikTextField
            name='assignServiceWorkerDelivered'
            label='Assign to Deliver'
          />
          <Box mb={1} />

          <Stack display='flex' flexDirection='row' flex={1} pt={2}>
            <Stack display='flex' flexDirection='column' mr={3}>
              <PhotoUpload
                isMultiple
                hideMenuItemDelete
                inputFileRef={inputFileDeliverRef}
                fileToUpload={fileToUploadDeliver}
                avatarSize={80}
                setFileToUpload={files => {
                  if (!files) return
                  const temp = [] as File[]
                  Array.from(files).forEach(file => temp.push(file))
                  setFileListToUpload(prev => [...prev, ...temp])
                  formikWorkFinishToDelivered.setFieldValue('hasImage', true)
                }}
                showTextAllowFile
              />
            </Stack>
            <Stack
              display='flex'
              flexDirection='row'
              flex={1}
              alignItems='center'
            >
              {RenderFileListToUpload()}
            </Stack>
          </Stack>
          {formikWorkFinishToDelivered.errors.hasImage && (
            <FormHelperText sx={{ color: red[700] }}>
              {formikWorkFinishToDelivered.errors.hasImage}
            </FormHelperText>
          )}

          <Box mb={2} />

          <LoadingButton
            variant='contained'
            color='btnPrimaryFill'
            disableElevation
            fullWidth
            size='large'
            loading={loadingSave}
            type='submit'
          >
            Deliver
          </LoadingButton>
        </Form>
      </FormikProvider>
    )

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            borderRadius: 2,
            overflow: 'hidden',
            border: `1px solid ${StatusServiceFlowColors[status].color[500]}`,
          }}
        >
          <Stack
            height='calc(100vh - 62px)'
            paddingBottom={0}
            sx={{
              display: 'block',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <Header
              onRightIcon={() => {
                resetFormAll()
                setStep(1)
              }}
              title={EnumServiceStatus[status]}
              backgroundColor={StatusServiceFlowColors[status].color[500]}
            />

            <Stack
              sx={{
                height: 'calc(100% - 65px)',
                overflowY: { xs: 'auto', sm: 'hidden' },
              }}
            >
              <Grid
                container
                sx={{ height: { xs: 'auto', sm: '100%' }, width: '100%' }}
              >
                <Grid
                  item
                  xs={12}
                  sm={serviceStepSelected?.status !== 'AWAITING_APPROVAL' ? 6 : 12}
                  sx={{
                    display: 'block',
                    height: '75%',
                  }}
                >
                  <Stack
                    sx={{
                      display: 'block',
                      height: '100%',
                      overflowY: { xs: 'hidden', sm: 'auto' },
                      paddingX: 4,
                      paddingY: 2,
                    }}
                  >
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottom: `1px solid ${grey[200]}`,
                        paddingBottom: 2,
                        marginBottom: 2,
                      }}
                    >
                      <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                        KEY: {id.substring(0, 6).toUpperCase()}
                      </Typography>
                      <Box mb={1} />
                      <Typography variant='caption' color={grey[500]}>
                        Created:{' '}
                        {moment(createdAt).format(
                          'dddd, MMMM Do YYYY, h:mm:ss a',
                        )}
                      </Typography>
                      <Typography variant='caption' color={grey[500]}>
                        Updated:{' '}
                        {moment(updatedAt).format(
                          'dddd, MMMM Do YYYY, h:mm:ss a',
                        )}
                      </Typography>
                    </Stack>

                    <Typography variant='h6'>{title}</Typography>
                    <Box mb={1} />

                    <Typography variant='subtitle2'>Description:</Typography>
                    <Typography variant='body1'>{description}</Typography>
                  </Stack>
                </Grid>

                {serviceStepSelected?.status !== 'AWAITING_APPROVAL' && (
                  <Grid item xs={12} sm={6}
                    sx={{ borderLeft: { xs: 'none', sm: `1px dashed ${grey[200]}` }, }}>
                    <Stack sx={{ paddingX: 4, paddingY: 2 }}>
                      {status === 'APPROVED' && RenderStepApproved()}
                      {status === 'START_WORK' && RenderStepStarWork()}
                      {status === 'WORK_FINISH' && RenderStepWorkFinish()}
                    </Stack>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '25%',
                    width: '100%',
                    justifyContent: 'center',
                    paddingX: 2,
                    borderTop: `1px dashed ${grey[300]}`,
                  }}
                >
                  <Typography variant='subtitle2' mb={1}>Images saved</Typography>
                  {RenderFileListFromBucket()}
                </Grid>

              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Box >
    )
  }

  useEffect(() => {
    if (serviceList === undefined) {
      ApiService.Service.getList({
        companyId,
        whereList: [
          where('isActive', '==', true),
          where('typeService', '==', 'BUCKET'),
          where('status', 'in', [
            'TO_DO',
            'IN_PROGRESS',
            'AWAITING_APPROVAL',
            'APPROVED',
            'START_WORK',
            'WORK_FINISH',
          ]),
        ],
        setLoadingList,
        setServiceList: list => {
          const sort = (list || [])?.sort((a, b) => a.createdAt - b.createdAt)
          setServiceList(sort)
        },
      })
    }
  }, [serviceList, ApiService.Service.getList])


  const getAllFiles = async (serviceSelectedId: string) => {
    setLoadingFiles(true)
    await ApiService.File.getFiles({
      pathFile: getPathFileCompanyService(companyId, serviceSelectedId),
    })
      .then(resp => {
        setFileFromStorageList(resp)
      })
      .finally(() => {
        setLoadingFiles(false)
        setHasNewImageSaved(false)
      })
  }

  useEffect(() => {
    if (!serviceStepSelected) return
    getAllFiles(serviceStepSelected.id)
  }, [serviceStepSelected])

  useEffect(() => {
    if (!serviceStepSelected || !hasNewImageSaved) return
    getAllFiles(serviceStepSelected.id)
  }, [serviceStepSelected, hasNewImageSaved])

  return (
    <Box>
      <Stack>
        {isRoleMechanic && (
          <Stack paddingX={0.5}>
            <HeaderUser />
          </Stack>
        )}
        {!hideBreadcrumb && (
          <Breadcrumb
            pageTitle='Flow of services bucket'
            menu={breadcrumbList}
          />
        )}
      </Stack>

      <Box
        sx={{
          display: 'flex',
          height: 'calc(100% - 60px)',
          padding: '0px 10px 10px',
        }}
      >
        {step === 1 && RenderStepHome()}
        {step === 2 && serviceStepSelected && RenderStepFlow()}
      </Box>

      <DialogConfirm
        title='Change status'
        description={showDialoadChangeStatus?.tile}
        maxWidth='sm'
        open={!!showDialoadChangeStatus}
        onCloseDialog={() => { setShowDialoadChangeStatus(undefined) }}
        btnOkProps={{
          loading: loadingSave,
          onClick: () => {
            if (showDialoadChangeStatus?.currentStatus === 'APPROVED') {
              formikApprovedToStartWork.setFieldValue('confirmedChange', true)
              formikApprovedToStartWork.submitForm()
            }
            if (showDialoadChangeStatus?.currentStatus === 'START_WORK') {
              formikStartWorkToWorkFinish.setFieldValue('confirmedChange', true)
              formikStartWorkToWorkFinish.submitForm()
            }
            if (showDialoadChangeStatus?.currentStatus === 'WORK_FINISH') {
              formikWorkFinishToDelivered.setFieldValue('confirmedChange', true)
              formikWorkFinishToDelivered.submitForm()
            }
          },
        }}
        btnNoProps={{
          onClick: () => { setShowDialoadChangeStatus(undefined) },
        }}
      />
    </Box>
  )
}

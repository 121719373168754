import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, Breadcrumb, FormikTextField, FormikSwitch, DialogConfirm, PhotoUpload } from '@/components'
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, Stack } from '@mui/material'

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472

const INITIAL_VALUES: Omit<Supplier, 'id' | 'createdAt' | 'updatedAt' | 'dateCreatedAt' | 'nameSearch' | 'emailSearch'> = {
  name: '',
  email: '',
  phoneNumber: '',
  phoneNumberSecondary: '',
  address: '',
  websiteUrl: '',
  isActive: true,
}

export const SupplierCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)

  const locationStateSupplierSelected = location.state?.supplierSelected ? JSON.parse(location.state?.supplierSelected) : undefined
  const [supplierSelected, setSupplierSelected] = useState<Supplier | undefined>(locationStateSupplierSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'Supplier',
      title: 'Suppliers',
      href: RoutesNames.SUPPLIER,
    },
    {
      id: 'supplier-crud',
      title: supplierSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]

  const resetForm = () => {
    formik.resetForm()
    setFileToUpload(undefined)
    setSupplierSelected(undefined)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      if (!supplierSelected) {
        await ApiService.Supplier.save({
          companyId,
          values
        }).then(() => {
          resetForm()
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Save successfull' }
          })
        }).catch(() => {
          dispatch({
            type: 'SET_SNACKBAR',
            data: { message: 'Error on save', alertSeverity: 'error' }
          })
        }).finally(() => {
          setLoadingSave(false)
        })
        return
      }

      await ApiService.Supplier.update({
        companyId,
        supplierId: supplierSelected?.id,
        values
      }).then(() => {
        resetForm()
        navigate(RoutesNames.SUPPLIER)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Save successfull' }
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })
    },
  })

  const handleDeleteSupplier = async () => {
    if (!supplierSelected) return
    setLoadingDelete(true)
    await ApiService.Supplier.remove({ companyId, supplierId: supplierSelected.id })
      .then(async () => {
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Supplier deleted' }
        })
        navigate(RoutesNames.SUPPLIER)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Supplier was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  useEffect(() => {
    if (!supplierSelected) return
    setInitialValues(supplierSelected)
  }, [supplierSelected])

  return (
    <Box>
      <Breadcrumb
        pageTitle={
          supplierSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isDisableUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                    />
                  </Stack>

                  <FormikSwitch size='small' name='isActive' label='Enable supplier' />

                  {supplierSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>

                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={12}>
                          <FormikTextField name='name' fullWidth label='Name' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='email' fullWidth label='E-mail' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextField name='phoneNumber' fullWidth label='Phone number' />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikTextField name='phoneNumberSecondary' fullWidth label='Phone number secondary' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='address' fullWidth label='Address' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='websiteUrl' fullWidth label='Website url' />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => {
                        navigate(RoutesNames.SUPPLIER)
                      }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                    >
                      {supplierSelected ? 'Save update' : 'Save supplier'}
                    </LoadingButton>
                  </Stack>
                </Stack>

              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${supplierSelected?.name}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteSupplier() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />
    </Box>
  )
}

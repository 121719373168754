import React from 'react'

import { Box, Stack } from '@mui/material'

import { CircularProgress } from '../circular-progress'

interface LoadingTableProps {
    height?: number
    position?: 'absolute' | 'relative'
}
export const LoadingTable = ({ height = 400, position = 'absolute' }: LoadingTableProps) => (
    <Stack
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
        position={position}
        height={height}
    >
        <Box sx={{ position: 'relative' }}>
            <CircularProgress size={40} />
        </Box>
    </Stack>
)

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import {
  TbCheckbox,
  TbChevronRight,
  TbCircleMinus,
  TbCirclePlus,
  TbDotsVertical,
  TbPencil,
  TbPlus,
  TbSearch,
  TbShoppingCart,
  TbShoppingCartCopy,
  TbShoppingCartMinus,
  TbShoppingCartPlus,
  TbShoppingCartX,
  TbTrash,
  TbTrashFilled,
  TbX,
} from 'react-icons/tb'



import { orderBy, QueryFieldFilterConstraint, where } from 'firebase/firestore'

import { useGlobalState } from '@/context'
import * as ApiService from '@/services/api'
import { theme } from '@/styles/mui-style'
import { formatNumberToLibra } from '@/utils/currency'
import {
  Badge,
  Box,
  Drawer,
  FormHelperText,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Menu,
  TextField,
  Typography,
  useMediaQuery,
  ListItem,
  Checkbox,
  InputAdornment,
  Button,
  BadgeProps,
  ListItemButton,
} from '@mui/material'
import { green, grey, red } from '@mui/material/colors'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles';

import { CircularProgress } from '../circular-progress'
import { LoadingTable } from '../loadind-table'
import { NoContent } from '../no-content'

interface DrawerChooseStockProps {
  stockWithCheckListChoosed?: StockWithCheck[]
  errorMessage?: string
  stockChoosed?: Stock
  stockIdToSelect?: string | undefined
  setStockWithCheckListChoosed: (stockWithCheckList: StockWithCheck[]) => void
  reset: () => void
}

interface ShoppingCartProps {
  badge?: number
  isDisabled: boolean
  onClick: () => void
}
const ShoppingCart = ({ badge, isDisabled, onClick }: ShoppingCartProps) => {
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      top: 6,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <IconButton
      size='large'
      disabled={isDisabled}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <StyledBadge
        badgeContent={badge || '--'} color={isDisabled ? 'default' : 'primary'}>
        <TbShoppingCart
          color={grey[isDisabled ? 400 : 900]}
        />
      </StyledBadge>
    </IconButton>
  );
}

export const DrawerChooseStock = ({
  stockWithCheckListChoosed,
  setStockWithCheckListChoosed,
  errorMessage,
  reset,
}: DrawerChooseStockProps) => {
  const [{ company }, dispatch] = useGlobalState()
  const [stockList, setStockList] = useState<StockWithCheck[] | undefined>(
    undefined,
  )
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [searchStock, setSearchStock] = useState<string>('')
  const [showDrawerChooseStock, setShowDrawerChooseStock] =
    useState<boolean>(false)
  const [showDrawerEditStock, setShowDrawerEditStock] =
    useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)

  const open = Boolean(anchorEl)
  const companyId = useMemo(() => company?.id || '', [])
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const hasSearchStock = useMemo(() => {
    if (searchStock && searchStock.length > 0) return true
    return false
  }, [searchStock])

  const hasAnyChoosed = useMemo(
    () => (stockWithCheckListChoosed || []).length > 0,
    [stockWithCheckListChoosed],
  )

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleMenuAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCheckUncheck = (stockItem: StockWithCheck, isAdd: boolean) => {

    const selectStockList = stockList?.map((item) => {
      if (item.id === stockItem.id) {
        return { ...item, isAddCart: isAdd ? true : !item.isAddCart }
      }
      return item
    })
    setStockList(selectStockList)

    if (isAdd) {
      const thereIsChoosed = stockWithCheckListChoosed?.some((item) => item.id === stockItem.id)

      if (thereIsChoosed && stockWithCheckListChoosed) {
        const add = stockWithCheckListChoosed?.map((item) => {
          if (item.id === stockItem.id) {
            return { ...item, howManyQuantity: stockItem.howManyQuantity }
          }
          return item
        })
        setStockWithCheckListChoosed(add)
      } else {
        setStockWithCheckListChoosed([...(stockWithCheckListChoosed || []), stockItem])
      }
      return
    }

    const filter = stockWithCheckListChoosed?.filter(item => item.id !== stockItem.id)
    setStockWithCheckListChoosed(filter || [])
  }
  const handleHowManyQuantity = (stockItem: Stock, quantity: number) => {
    const response = stockList?.map(item => {
      if (stockItem.id === item.id) {
        return { ...item, howManyQuantity: quantity }
      }
      return item
    })

    setStockList(response)
  }

  const handleCheckUncheckEdit = (stockItem: StockWithCheck) => {
    const selectStockList = stockList?.map((item) => {
      if (item.id === stockItem.id) {
        return { ...item, isAddCart: !item.isAddCart }
      }
      return item
    })
    setStockList(selectStockList)

    const stockChoosedTemp = stockWithCheckListChoosed?.filter(item => item.id !== stockItem.id)
    setStockWithCheckListChoosed(stockChoosedTemp || [])
  }
  const handleHowManyQuantityEdit = (stockItem: StockWithCheck, quantity: number) => {
    const response = stockWithCheckListChoosed?.map(item => {
      if (stockItem.id === item.id) {
        return { ...item, howManyQuantity: quantity }
      }
      return item
    })
    setStockWithCheckListChoosed(response || [])
  }

  const handleReset = () => {
    setStockWithCheckListChoosed([])
    setSearchStock('')
    reset()
    setShowDrawerChooseStock(false)
    setShowDrawerEditStock(false)
  }

  const handleCloseDrawer = () => {
    setShowDrawerChooseStock(false)
    setSearchStock('')
    setStockList(undefined)
  }

  const handleCloseEditDrawer = () => {
    setShowDrawerEditStock(false)
  }

  const getStock = (stockName: string) => {
    const whereList: QueryFieldFilterConstraint[] = [where('titleSearch', '>=', stockName.toUpperCase()), where('titleSearch', '<=', stockName.toUpperCase() + `\uf8ff`)]
    const orderByList = [orderBy('titleSearch', 'asc')]

    ApiService.Stock.getList({
      companyId,
      setStockList: stockListApi => {
        if (!stockListApi || stockListApi?.length === 0) {
          setStockList([])
          return
        }

        const response = stockListApi?.map(item => {
          const isAddCart = stockWithCheckListChoosed?.some((choosed) => choosed.id === item.id)

          const newData = {
            id: item.id,
            title: item.title,
            price: item.price,
            quantity: item.quantity,
            isChecked: false,
            isActive: item.isActive,
            howManyQuantity: 1,
            isAddCart,
          } as StockWithCheck
          return newData
        })
        const listSorted = response.sort((a, b) =>
          a.title.localeCompare(b.title),
        )
        setStockList(listSorted)
        // setStockListOriginal(listSorted)
      },
      setLoadingList,
      whereList,
      orderByList
    })
  }

  return (
    <Box>
      <Stack
        display='flex'
        flexDirection='row'
        sx={{
          height: 54,
          width: '100%',
          borderRadius: 1,
          border: `1px solid ${errorMessage ? red[700] : grey[200]}`,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: 1.5,
        }}
      >
        {loadingList && <CircularProgress />}
        {!loadingList && (
          <Stack
            display='flex'
            flex={1}
            alignItems='center'
            flexDirection='row'
            flexWrap='nowrap'
          >
            {hasAnyChoosed ? (
              <>
                {/* <AvatarLetter name={(stockChoosed?.title || '').substring(0, 1).toUpperCase()} /> */}
                <Typography
                  variant='body2'
                  textOverflow='ellipsis'
                  noWrap
                  width={150}
                  flex={1}
                  // display='flex'
                  // overflow='hidden'
                  // minWidth={0}
                  // whiteSpace='nowrap'
                  // flexShrink={0}
                  ml={1}
                >
                  {stockWithCheckListChoosed?.map(item => item.title).join(', ')}
                </Typography>
              </>
            ) : (
              <Typography
                variant='body2'
                flex={1}
                color={errorMessage ? red[700] : 'inherit'}
              >
                Select parts
              </Typography>
            )}
            {hasAnyChoosed ? (
              <IconButton
                sx={{ height: 40, width: 40 }}
                onClick={handleMenuAction}
              >
                <TbDotsVertical color={grey[600]} />
              </IconButton>
            ) : (
              <IconButton
                sx={{ height: 40, width: 40 }}
                onClick={() => {
                  setShowDrawerChooseStock(true)
                }}
              >
                <TbChevronRight color={grey[600]} />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>

      {errorMessage && (
        <FormHelperText sx={{ pl: 1.8, color: red[700] }}>
          {errorMessage}
        </FormHelperText>
      )}

      <Drawer
        open={showDrawerChooseStock}
        anchor='right'
        onClose={handleCloseDrawer}
        PaperProps={{ sx: { width: isXs ? '100%' : '50%' } }}
      >
        <Stack display='flex' flex={1}>
          <Stack position='absolute' right='12px' top='12px'>
            <IconButton
              onClick={handleCloseDrawer}
            >
              <TbX color='#fff' />
            </IconButton>
          </Stack>
          <Stack paddingBottom={2} marginBottom={1}
            sx={{ backgroundColor: grey[900], padding: 2 }}
          >
            <Typography variant='h6' color={'#fff'}>Add part</Typography>
          </Stack>

          <Stack padding={2}>
            <Stack display='flex' flexDirection='row'>
              <Stack display='flex' flexDirection='row' flex={1}>
                <TextField
                  label='Search part'
                  fullWidth
                  value={searchStock}
                  onChange={(event) => { setSearchStock(event.target.value); }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSearchStock('')
                            setStockList(undefined)
                          }}
                          disabled={!hasSearchStock}
                        >
                          <TbX color={grey[hasSearchStock ? 900 : 400]} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  size='large'
                  onClick={() => {
                    getStock(searchStock)
                  }}
                  disabled={!hasSearchStock}
                >
                  <TbSearch color={grey[hasSearchStock ? 900 : 400]} />
                </IconButton>
              </Stack>

              <Stack sx={{ ml: 5 }}>
                <ShoppingCart
                  badge={(stockWithCheckListChoosed || []).length}
                  isDisabled={(stockWithCheckListChoosed || []).length === 0}
                  onClick={() => {
                    setShowDrawerEditStock(true)
                  }} />
              </Stack>
            </Stack>

            <Stack mt={5}>
              <Typography variant='h6' >
                Result of filter
              </Typography>
              {loadingList && (
                <LoadingTable height={300} position='relative' />
              )}
              {!loadingList && stockList && stockList?.length > 0 && (
                <List>
                  {stockList?.map((stock, index) => {
                    const noStock = !stock.quantity

                    return (
                      <ListItemButton
                        disabled={noStock}
                        disableGutters
                        key={stock.id}
                        selected={stock.isAddCart}
                        sx={{ borderRadius: 2, cursor: 'default' }}
                      >
                        <Stack display='flex' flexDirection='row' flex={1} px={1}>
                          <Stack display='flex' flexDirection='column' flex={1}>
                            <Typography variant='body1'>
                              {stock.title}
                            </Typography>
                            <Typography variant='caption'>
                              Price:{' '}
                              <Typography
                                variant='caption'
                                color={grey[900]}
                                fontWeight='bold'
                              >
                                {formatNumberToLibra({
                                  value: stock.price,
                                  showCurrency: true,
                                })}
                              </Typography>
                            </Typography>
                            <Typography variant='caption'>
                              Quantity in stock:{' '}
                              <Typography
                                variant='caption'
                                color={grey[900]}
                                fontWeight='bold'
                              >
                                {stock.quantity || 0}
                              </Typography>
                            </Typography>
                          </Stack>

                          <Stack display='flex' flexDirection='row' alignItems='center'>
                            <IconButton
                              onClick={() => {
                                if (stock.howManyQuantity === 1) return
                                handleHowManyQuantity(
                                  stock,
                                  stock.howManyQuantity - 1,
                                )
                              }}
                            >
                              <TbCircleMinus color={grey[900]} />
                            </IconButton>
                            <Typography
                              variant='caption'
                              fontWeight='bold'
                              marginX={1}
                            >
                              {stock.howManyQuantity}
                            </Typography>
                            <IconButton
                              onClick={() => {
                                if (stock.howManyQuantity === stock.quantity) return
                                handleHowManyQuantity(
                                  stock,
                                  stock.howManyQuantity + 1,
                                )
                              }}
                            >
                              <TbCirclePlus color={grey[900]} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (!stock.isActive) {
                                  dispatch({
                                    type: 'SET_SNACKBAR',
                                    data: { message: 'Inactive part!', alertSeverity: 'warning' }
                                  })
                                  return
                                }
                                if (!stock.quantity) {
                                  dispatch({
                                    type: 'SET_SNACKBAR',
                                    data: { message: 'Zero stock!', alertSeverity: 'warning' }
                                  })
                                  return
                                }
                                handleCheckUncheck(stock, true)
                              }}
                              sx={{ ml: 4 }}>
                              <TbShoppingCartPlus color={green[500]} />
                            </IconButton>
                            <Stack>-</Stack>
                            <IconButton onClick={() => { handleCheckUncheck(stock, false) }}>
                              <TbShoppingCartX color={red[500]} />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </ListItemButton>
                    )











                    // return (
                    //   <ListItem
                    //     disableGutters
                    //     selected
                    //     disabled={!stock.isActive || !stock.quantity}
                    //     key={stock.id}
                    //     secondaryAction={
                    //       <Stack
                    //         flexDirection='row'
                    //         display='flex'
                    //         alignItems='center'
                    //       >

                    //       </Stack>
                    //     }
                    //     disablePadding
                    //   >
                    //     <ListItem disableGutters sx={{ borderRadius: 2 }}>
                    //       <ListItemText
                    //         primary={stock.title}
                    //         secondary={
                    //           <Stack pt={0.5}>

                    //           </Stack>
                    //         }
                    //       />
                    //     </ListItem>
                    //   </ListItem>
                    // )





                  })}
                </List>
              )}
              {!loadingList && stockList && stockList?.length === 0 && (
                <NoContent
                  size={150}
                  subtitle='No results for the filter'
                />
              )}
              {!loadingList && !stockList && (
                <NoContent
                  type='NO_SEARCH_YET'
                  size={150}
                  subtitle='No parts searched'
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Drawer>

      <Drawer
        open={showDrawerEditStock}
        anchor='right'
        onClose={handleCloseEditDrawer}
        PaperProps={{ sx: { width: isXs ? '100%' : '45%' } }}
      >
        <Stack display='flex' flex={1}>
          <Stack position='absolute' right='12px' top='12px'>
            <IconButton
              onClick={handleCloseEditDrawer}
            >
              <TbX color='#fff' />
            </IconButton>
          </Stack>
          <Stack paddingBottom={2} marginBottom={1}
            sx={{ backgroundColor: grey[900], padding: 2 }}
          >
            <Typography variant='h6' color={'#fff'}>Edit cart</Typography>
          </Stack>

          <Stack padding={2}>
            <Typography variant='h6' >
              Parts selected
            </Typography>
            <List>
              {stockWithCheckListChoosed?.map((stock) => (
                <ListItem
                  key={stock.id}
                  secondaryAction={
                    <Stack flexDirection='row' display='flex' alignItems='center'>
                      <IconButton
                        onClick={() => {
                          if ((stock.howManyQuantity - 1) === 0) {
                            handleCheckUncheckEdit(stock)
                            return
                          }
                          handleHowManyQuantityEdit(stock, stock.howManyQuantity - 1)
                        }}
                      >
                        <TbCircleMinus color={grey[900]} />
                      </IconButton>
                      <Stack>-</Stack>
                      <IconButton
                        onClick={() => {
                          if (stock.howManyQuantity === stock.quantity) return
                          handleHowManyQuantityEdit(
                            stock,
                            stock.howManyQuantity + 1,
                          )
                        }}
                      >
                        <TbCirclePlus color={grey[900]} />
                      </IconButton>
                      <IconButton onClick={() => { handleCheckUncheckEdit(stock) }} sx={{ ml: 2 }} >
                        <TbTrash color={red[500]} />
                      </IconButton>
                    </Stack>
                  }
                  disablePadding
                >
                  <ListItem disableGutters sx={{ borderRadius: 2 }}>
                    <ListItemText
                      primary={stock.title}
                      secondary={
                        <Stack pt={0.5}>
                          <Typography variant='caption'>
                            Price:{' '}
                            <Typography
                              variant='caption'
                              color={grey[900]}
                              fontWeight='bold'
                            >
                              {formatNumberToLibra({
                                value: stock.price,
                                showCurrency: true,
                              })}
                            </Typography>
                          </Typography>
                          <Typography variant='caption'>
                            Quantity in stock:{' '}
                            <Typography
                              variant='caption'
                              color={grey[900]}
                              fontWeight='bold'
                            >
                              {stock.quantity || 0}
                            </Typography>
                          </Typography>
                          <Typography variant='caption'>
                            Quantity choosed:{' '}
                            <Typography
                              variant='caption'
                              color={grey[900]}
                              fontWeight='bold'
                            >
                              {stock.howManyQuantity || 0}
                            </Typography>
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack >
      </Drawer >

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setShowDrawerChooseStock(true)
          }}
        >
          <ListItemIcon>
            <TbPlus size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Add</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setShowDrawerEditStock(true)
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            handleReset()
          }}
        >
          <ListItemIcon>
            <TbCheckbox size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Uncheck all
          </Typography>
        </MenuItem>
      </Menu>
    </Box >
  )
}

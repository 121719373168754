import {
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
} from 'firebase/firestore'

import { FirebaseCollection } from '@/constants/firebase-collection'
import { firestore } from '@/services/firebase'

interface GetListProps {
  companyId: string
  setStockRequestList: (stockRequestList: StockRequest[] | undefined) => void
  setLoadingList: React.Dispatch<React.SetStateAction<boolean>>
  whereList?: QueryFieldFilterConstraint[]
  orderByList?: QueryOrderByConstraint[]
  startAfter?: QueryStartAtConstraint[]
  limit?: QueryLimitConstraint[]
}
export const getList = async ({
  companyId,
  setStockRequestList,
  setLoadingList,
  whereList,
  orderByList,
  startAfter,
  limit,
}: GetListProps) => {
  setLoadingList(true)
  const q = query(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK_REQUEST,
    ),
    ...(whereList || []),
    ...(orderByList || []),
    ...(startAfter || []),
    ...(limit || []),
  )

  onSnapshot(
    q,
    querySnapshot => {
      const stockRequestList: StockRequest[] = []
      querySnapshot.forEach(doc => {
        const dataItem = { id: doc.id, ...doc.data() } as StockRequest
        stockRequestList.push(dataItem)
      })
      setLoadingList(false)
      setStockRequestList(stockRequestList)
    },
    () => {
      console.log('stockRequest - getList() - error')
      setLoadingList(false)
    },
  )
}

interface SaveProps {
  companyId: string
  values: Partial<Stock>
}
export const save = async ({ companyId, values }: SaveProps) =>
  await addDoc(
    collection(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK_REQUEST,
    ),
    {
      ...values,
      createdAt: new Date().getTime(),
      dateCreatedAt: new Date().setHours(0, 0, 0, 0),
      updatedAt: new Date().getTime(),
    },
  ).then(resp => resp.id)

interface UpdateProps {
  companyId: string
  stockRequestId: string
  values: Partial<StockRequest>
}
export const update = async ({
  companyId,
  stockRequestId,
  values,
}: UpdateProps) => {
  await setDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK_REQUEST,
      stockRequestId,
    ),
    { ...values, updatedAt: new Date().getTime() },
    { merge: true },
  )
}

interface RemoveProps {
  companyId: string
  stockRequestId: string
}
export const remove = async ({ companyId, stockRequestId }: RemoveProps) => {
  await deleteDoc(
    doc(
      firestore,
      FirebaseCollection.BASE_COMPANY,
      companyId,
      FirebaseCollection.BASE_STOCK_REQUEST,
      stockRequestId,
    ),
  )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { orderBy } from 'firebase/firestore';
import { Form, FormikErrors, FormikHelpers, FormikProvider, FormikTouched, useFormik } from 'formik'
import moment from 'moment'

import { BoxShadow, Breadcrumb, FormikTextField, DialogConfirm, FormikTextNumericFormat, PhotoUpload, FormikSwitch, CircularProgress } from '@/components'
import { FormikSelectField, FormikSelectFieldMenu } from '@/components/formik-select-field';
import { EnumStockRequestStatus } from '@/constants/stock-request';
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import { grey } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472

interface FormikAutocompleteFieldMenu {
  id: string
  label: string
}

interface InitialValues extends Partial<StockRequest> {
  keyUnique: string
}
const INITIAL_VALUES: InitialValues = {
  title: '',
  supplierId: '',
  supplierName: '',
  stockId: '',
  stockTitle: '',
  price: 0,
  quantity: 0,
  status: 'REQUEST',
  supplierInvoiceNumber: '',
  paymentDue: moment().add(30, 'days').valueOf(),
  isPaidOut: false,
  keyUnique: '',
}

const stockRequestStatusList: FormikSelectFieldMenu[] = Object.entries(EnumStockRequestStatus).map(([key, value]) => ({
  value: key,
  title: value,
}))

interface AutocompleteStockProps {
  stockList: FormikAutocompleteFieldMenu[]
  formikHelpers: Pick<FormikHelpers<Partial<StockRequest>>, 'setFieldValue' | 'setFieldTouched'>
  formikErrors: FormikErrors<Partial<StockRequest>>
  formikTouched: FormikTouched<Partial<StockRequest>>
  stock: FormikAutocompleteFieldMenu | null
  setStock: React.Dispatch<React.SetStateAction<FormikAutocompleteFieldMenu | null>>
}
const AutocompleteStock = ({ stockList, formikHelpers, formikErrors, formikTouched, stock, setStock }: AutocompleteStockProps) => {
  const { setFieldValue, setFieldTouched } = formikHelpers

  return (
    <Autocomplete
      disablePortal
      value={stock}
      options={stockList}
      onChange={(_, data) => {
        if (!data) {
          setStock(null)
          setFieldValue('stockId', '')
          setFieldTouched('stockId', true)
          setFieldValue('stockTitle', '')
          return
        }
        setStock(data)
        setFieldValue('stockId', data.id)
        setFieldValue('stockTitle', data.label)
      }}
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select part from stock"
          error={formikTouched.stockId && Boolean(formikErrors.stockId)}
          helperText={formikErrors?.stockId || ""}
        />
      )}
    />
  )
}

interface AutocompleteSupplierProps {
  supplierList: FormikAutocompleteFieldMenu[]
  formikHelpers: Pick<FormikHelpers<Partial<StockRequest>>, 'setFieldValue' | 'setFieldTouched'>
  formikErrors: FormikErrors<Partial<StockRequest>>
  formikTouched: FormikTouched<Partial<StockRequest>>
  supplier: FormikAutocompleteFieldMenu | null
  setSupplier: React.Dispatch<React.SetStateAction<FormikAutocompleteFieldMenu | null>>
}
const AutocompleteSupplier = ({ supplierList, formikHelpers, formikErrors, formikTouched, supplier, setSupplier }: AutocompleteSupplierProps) => {
  const { setFieldValue, setFieldTouched } = formikHelpers

  return (
    <Autocomplete
      disablePortal
      value={supplier}
      options={supplierList}
      onChange={(_, data) => {
        if (!data) {
          setSupplier(null)
          setFieldValue('supplierId', '')
          setFieldValue('supplierName', '')
          setFieldTouched('supplierId', true)
          return
        }
        setSupplier(data)
        setFieldValue('supplierId', data.id)
        setFieldValue('supplierName', data.label)
      }}
      getOptionLabel={(option) => option.label || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select supplier"
          error={formikTouched.supplierId && Boolean(formikErrors.supplierId)}
          helperText={formikErrors?.supplierId || ""}
        />
      )}
    />
  )
}

export const StockRequestCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined)
  const locationStatestockRequestSelected = location.state?.stockRequestSelected ? JSON.parse(location.state?.stockRequestSelected) : undefined
  const [stockRequestSelected] = useState<StockRequest | undefined>(locationStatestockRequestSelected)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [stockList, setStockList] = useState<FormikAutocompleteFieldMenu[]>([])
  const [loadingStockList, setLoadingStockList] = useState<boolean>(false)
  const [supplierList, setSupplierList] = useState<FormikAutocompleteFieldMenu[]>([])
  const [loadingSupplierList, setLoadingSupplierList] = useState<boolean>(false)
  const [stockPrevSelected, setStockPrevSelected] = React.useState<FormikAutocompleteFieldMenu | null>(null);
  const [supplierPrevSelected, setSupplierPrevSelected] = React.useState<FormikAutocompleteFieldMenu | null>(null);

  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'stockRequest',
      title: 'Purchase order',
      href: RoutesNames.STOCK_REQUEST,
    },
    {
      id: 'stock-request-crud',
      title: stockRequestSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]

  const resetForm = () => {
    formik.resetForm()
    setInitialValues(INITIAL_VALUES)
    setStockPrevSelected(null)
    setSupplierPrevSelected(null)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      const data: Partial<StockRequest> = {
        title: values.title,
        supplierId: values.supplierId || '',
        supplierName: values.supplierName || '',
        stockId: values.stockId || '',
        stockTitle: values.stockTitle || '',
        price: values.price || 0,
        quantity: values.quantity || 0,
        status: values.status || 'REQUEST',
        supplierInvoiceNumber: values.supplierInvoiceNumber || null,
        paymentDue: values.paymentDue,
        isPaidOut: values.isPaidOut,
        isQuantityAdded: values.status !== 'REQUEST',
        ...(!stockRequestSelected?.isPaidOut && values.isPaidOut ? { paymentCreatedAt: new Date().getTime() } : {})
      }

      if (!stockRequestSelected) {
        await ApiService.StockRequest.save({
          companyId,
          values: data
        })
          .then(() => {
            resetForm()
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Save successfull' }
            })
          }).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error on save', alertSeverity: 'error' }
            })
          }).finally(() => {
            setLoadingSave(false)
          })
        return
      }

      const stock = await ApiService.Stock.getOne({
        companyId, stockId: stockRequestSelected?.stockId
      })

      if (!stock) {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'The part is no longer in stock!', alertSeverity: 'error' }
        })
        return
      }

      await ApiService.StockRequest.update({
        companyId,
        stockRequestId: stockRequestSelected?.id,
        values: data
      }).then(async () => {

        if (values.status !== 'REQUEST' && !stockRequestSelected.isQuantityAdded) {
          const sumQuantity = Number(stock.quantity) + Number(values.quantity)

          await ApiService.Stock.update({
            companyId,
            stockId: stockRequestSelected.stockId,
            values: { quantity: sumQuantity }
          }).then(() => {
            resetForm()
            navigate(RoutesNames.STOCK_REQUEST)
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Stock incremented successful' }
            })
          }).catch(() => {
            dispatch({
              type: 'SET_SNACKBAR',
              data: { message: 'Error on update', alertSeverity: 'error' }
            })
          }).finally(() => {
            setLoadingSave(false)
          })
        }

        resetForm()
        navigate(RoutesNames.STOCK_REQUEST)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Status changed' }
        })

      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })
    },
  })

  const handleDeleteStock = async () => {
    if (!stockRequestSelected) return
    setLoadingDelete(true)
    await ApiService.StockRequest.remove({ companyId, stockRequestId: stockRequestSelected.id })
      .then(async () => {
        resetForm()
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request deleted' }
        })
        navigate(RoutesNames.STOCK_REQUEST)
      }).catch(() => {
        setIsOpenConfirmDelete(false)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Request was not deleted' }
        })
      }).finally(() => {
        setLoadingDelete(false)
      })
  }

  const getStockList = async () => {
    await ApiService.Stock.getList({
      companyId,
      setStockList: (list) => {
        if (!list) return
        const resp = list.map((item) => ({
          id: item.id,
          label: item.title,
        }))
        setStockList(resp as FormikAutocompleteFieldMenu[])
      },
      setLoadingList: setLoadingStockList,
      orderByList: [orderBy('title', 'asc')],
    })
  }

  const getSupplierList = async () => {
    await ApiService.Supplier.getList({
      companyId,
      setSupplierList: (list) => {
        if (!list) return
        const resp = list.map((item) => ({
          id: item.id,
          label: item.name,
        }))
        setSupplierList(resp as FormikAutocompleteFieldMenu[])
      },
      setLoadingList: setLoadingSupplierList,
      orderByList: [orderBy('name', 'asc')],
    })
  }

  useEffect(() => {
    if (stockList.length === 0 || supplierList.length === 0) {
      getStockList()
      getSupplierList()
      return
    }

    if (!stockRequestSelected) return

    const stockItem = stockList.find((item) => item.id === stockRequestSelected.stockId)
    const supplierItem = supplierList.find((item) => item.id === stockRequestSelected.supplierId)

    setStockPrevSelected(stockItem || null)
    setSupplierPrevSelected(supplierItem || null)

    if (!supplierItem || !stockItem) return

    setInitialValues({
      title: stockRequestSelected.title,

      supplierId: stockRequestSelected.supplierId,
      supplierName: stockRequestSelected.supplierName,

      stockId: stockRequestSelected.stockId,
      stockTitle: stockRequestSelected.stockTitle,

      price: stockRequestSelected.price,
      quantity: stockRequestSelected.quantity,
      status: stockRequestSelected.status,
      supplierInvoiceNumber: stockRequestSelected.supplierInvoiceNumber,
      paymentDue: moment(stockRequestSelected.paymentDue).valueOf(),
      isPaidOut: stockRequestSelected.isPaidOut,
      keyUnique: stockRequestSelected.id.substring(0, 6).toUpperCase(),
    })

  }, [stockRequestSelected, stockList, supplierList])


  console.log('---> ', stockRequestSelected?.paymentCreatedAt ? moment(stockRequestSelected?.paymentCreatedAt).format('dddd, MMMM Do YYYY, h:mm:ss a') : 'NOPS');


  return (
    <Box>
      <Breadcrumb
        pageTitle={
          stockRequestSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  alignItems='center'
                  justifyContent='center'
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    alignItems='center'
                    justifyContent='center'
                    paddingTop='20px'
                  >
                    <PhotoUpload
                      isDisableUpload
                      inputFileRef={inputFileRef}
                      fileToUpload={fileToUpload}
                      setFileToUpload={(files) => {
                        if (!files) return
                        setFileToUpload(files[0])
                      }}
                    />
                  </Stack>

                  <FormikSwitch
                    size='small'
                    name='isPaidOut'
                    label={formik.values.isPaidOut ? 'Paid out' : 'Not paid out'}
                    // disabled={!stockRequestSelected || !formik.values.supplierInvoiceNumber}
                    onChange={(event) => {
                      if (stockRequestSelected?.isPaidOut) {
                        dispatch({
                          type: 'SET_SNACKBAR',
                          data: { message: 'This field cannot be changed anymore', alertSeverity: 'warning' }
                        })
                        formik.setFieldValue('isPaidOut', true)
                        return
                      }

                      if (!formik.values.supplierInvoiceNumber) {
                        dispatch({
                          type: 'SET_SNACKBAR',
                          data: { message: 'To enable payment status, you must enter invoice number', alertSeverity: 'warning' }
                        })
                        formik.setFieldValue('isPaidOut', false)
                        return
                      }

                      formik.setFieldValue('isPaidOut', event.target.checked)
                    }}
                  />

                  {stockRequestSelected && (
                    <>
                      <Box mb={2} />
                      <LoadingButton
                        color='btnError'
                        variant='contained'
                        disableElevation
                        disabled={stockRequestSelected.isPaidOut}
                        onClick={() => { setIsOpenConfirmDelete(true) }}
                      >
                        Delete
                      </LoadingButton>
                    </>
                  )}
                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>
                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={8}>
                          <FormikTextField name='title' label='Title request' />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                          <FormikTextField
                            name='keyUnique'
                            label={stockRequestSelected ? 'Key unique' : 'Key unique soon...'}
                            placeholder=''
                            disabled
                          />
                        </Grid>
                        <Grid item sm={12}>
                          {!loadingSupplierList && (
                            <AutocompleteSupplier
                              supplierList={supplierList}
                              formikHelpers={{ setFieldValue: formik.setFieldValue, setFieldTouched: formik.setFieldTouched }}
                              formikErrors={formik.errors}
                              formikTouched={formik.touched}
                              supplier={supplierPrevSelected}
                              setSupplier={setSupplierPrevSelected}
                            />
                          )}
                          {loadingSupplierList && (
                            <Stack width='100%' justifyContent='center' alignItems='center' border={`1px solid ${grey[200]}`} height={54} borderRadius={1}>
                              <CircularProgress />
                            </Stack>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {!loadingStockList && (
                            <AutocompleteStock
                              stockList={stockList}
                              formikHelpers={{ setFieldValue: formik.setFieldValue, setFieldTouched: formik.setFieldTouched }}
                              formikErrors={formik.errors}
                              formikTouched={formik.touched}
                              stock={stockPrevSelected}
                              setStock={setStockPrevSelected}
                            />
                          )}
                          {loadingStockList && (
                            <Stack width='100%' justifyContent='center' alignItems='center' border={`1px solid ${grey[200]}`} height={54} borderRadius={1}>
                              <CircularProgress />
                            </Stack>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <FormikSelectField
                            name='status'
                            fullWidth
                            label='Status'
                            menuList={stockRequestStatusList}
                            disabled={!stockRequestSelected}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField name='quantity' label='Quantity' type='number' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextNumericFormat name='price' label='Price' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikTextField name='supplierInvoiceNumber' label='Supplier invoice number' />
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DemoContainer components={['DatePicker']}>
                              <FormControl fullWidth>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: formik.touched.paymentDue && Boolean(formik.errors.paymentDue),
                                      helperText: (formik.touched.paymentDue && Boolean(formik.errors.paymentDue)) ? formik.errors.paymentDue?.toString() || '' : ''
                                    },
                                    field: {
                                      clearable: true,
                                      onClear: () => { formik.setFieldValue('paymentDue', null) },
                                    },
                                  }}
                                  format='DD/MM/YYYY'
                                  label="Payment due (today + 30d)"
                                  minDate={moment()}
                                  name='paymentDue'
                                  value={formik.values.paymentDue ? moment(formik.values.paymentDue) : null}
                                  onChange={(date) => {
                                    if (!date) {
                                      formik.setFieldValue('paymentDue', null, false)
                                      formik.setFieldTouched('paymentDue', true)
                                    } else {
                                      formik.setFieldValue('paymentDue', date.valueOf())
                                    }
                                  }}
                                />
                              </FormControl>
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => { navigate(RoutesNames.STOCK_REQUEST) }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                      disabled={loadingSupplierList || loadingStockList
                        // || stockRequestSelected?.status !== 'REQUEST'
                      }
                    >
                      {stockRequestSelected ? 'Save update' : 'Save request'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <DialogConfirm
        title='Delete'
        description={`Do you really want to delete "${stockRequestSelected?.title}"?`}
        open={isOpenConfirmDelete}
        onCloseDialog={() => {
          setIsOpenConfirmDelete(false)
        }}
        btnOkProps={{
          color: 'error',
          onClick: () => { handleDeleteStock() },
          loading: loadingDelete,
        }}
        btnNoProps={{
          variant: 'outlined',
          onClick: () => { setIsOpenConfirmDelete(false) },
        }}
      />

    </Box >
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom'

import { Form, FormikProvider, useFormik } from 'formik'

import { BoxShadow, Breadcrumb, FormikTextField, FormikSwitch, FormikTextNumericFormat, CircularProgress } from '@/components'
import { useGlobalState } from '@/context';
import { RoutesNames } from '@/router/routes'
import * as ApiService from '@/services/api'
import { formatNumberToLibra } from '@/utils/currency';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors';

import { validationSchema } from './validationSchema';

const BOX_HEIGHT = 472

const INITIAL_VALUES: Partial<ClientCredit> = {
  clientName: '',
  observation: '',
  amount: 0,
  isUsed: false,
}

export const OrderCreditNoteCrud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ company }, dispatch] = useGlobalState()
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const locationStateCreditSelected = location.state?.creditSelected ? JSON.parse(location.state?.creditSelected) : undefined
  const [creditSelected] = useState<ClientCredit | undefined>(locationStateCreditSelected)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const companyId = useMemo(() => company?.id || '', [])
  const [partsSold, setpartsSold] = useState<StockWithCheck[] | undefined>(undefined)
  const [loadingService, setLoadingService] = useState<boolean>(false)
  const breadcrumbList: Breadcrumb[] = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      href: RoutesNames.HOME,
    },
    {
      id: 'orderCreditsNotes',
      title: 'Credits notes',
      href: RoutesNames.ORDER_CREDIT_NOTE,
    },
    {
      id: 'orderCreditsNotes-crud',
      title: creditSelected ? 'Updating' : 'New',
      isCurrentPage: true,
    },
  ]

  const resetForm = () => {
    formik.resetForm()
    setInitialValues(INITIAL_VALUES)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoadingSave(true)

      await ApiService.ClientCredits.update({
        companyId,
        clientCreditId: creditSelected?.id || '',
        values
      }).then(() => {
        resetForm()
        navigate(RoutesNames.ORDER_CREDIT_NOTE)
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Save successfull' }
        })
      }).catch(() => {
        dispatch({
          type: 'SET_SNACKBAR',
          data: { message: 'Error on update', alertSeverity: 'error' }
        })
      }).finally(() => {
        setLoadingSave(false)
      })
    },
  })

  const getServiceData = async () => {
    if (!creditSelected?.serviceId) return
    setLoadingService(true)
    const response = await ApiService.Service
      .getOne({ companyId, serviceId: creditSelected.serviceId })
      .finally(() => {
        setLoadingService(false)
      })
    setpartsSold(JSON.parse(response?.partsSold || '[]'))
  }

  useEffect(() => {
    if (!creditSelected) return
    getServiceData()
    setInitialValues(creditSelected)
  }, [creditSelected,])

  return (
    <Box>
      <Breadcrumb
        pageTitle={
          creditSelected ? 'Update of data' : 'Registration'
        }
        menu={breadcrumbList}
      />

      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <BoxShadow>
                <Stack
                  display='flex'
                  minHeight={BOX_HEIGHT}
                  padding={3}
                >
                  <Stack
                    display='flex'
                    flex={1}
                    mb={2}
                  >
                    {loadingService && (
                      <Stack width='100%' justifyContent='center' alignItems='center' height={54}>
                        <CircularProgress />
                      </Stack>
                    )}
                    {!loadingService && (
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<TbChevronDown />}>
                          <Typography variant='body2'>Parts sold ({(partsSold || []).length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 1 }}>
                          <List>
                            {partsSold?.map((stock, index) => (
                              <>
                                <ListItem key={stock.id} disablePadding>

                                  <ListItemText
                                    // primary={stock.title}
                                    secondary={
                                      <Stack pt={0.5}>

                                        <Typography
                                          variant='caption'
                                          color={grey[900]}
                                          fontWeight='bold'
                                        >
                                          {stock.title}
                                        </Typography>

                                        <Typography variant='caption'>
                                          Quantity:{' '}
                                          <Typography
                                            variant='caption'
                                            color={grey[900]}
                                            fontWeight='bold'
                                          >
                                            {stock.howManyQuantity || 0}
                                          </Typography>
                                        </Typography>
                                        <Typography variant='caption'>
                                          Price:{' '}
                                          <Typography
                                            variant='caption'
                                            color={grey[900]}
                                            fontWeight='bold'
                                          >
                                            {formatNumberToLibra({
                                              value: stock.price,
                                              showCurrency: true,
                                            })}
                                          </Typography>
                                        </Typography>
                                      </Stack>
                                    }
                                  />
                                </ListItem>
                                {(index + 1) < partsSold.length && <Divider component="li" />}
                              </>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Stack>

                  <Stack
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <FormikSwitch
                      size='small'
                      name='isUsed'
                      label='Is credit used'
                      onChange={(event) => {
                        if (creditSelected?.isUsed) {
                          dispatch({
                            type: 'SET_SNACKBAR',
                            data: { message: 'This field cannot be changed anymore', alertSeverity: 'warning' }
                          })
                          formik.setFieldValue('isUsed', true)
                          return
                        }
                        formik.setFieldValue('isUsed', event.target.checked)
                      }}
                    />
                  </Stack>

                </Stack>
              </BoxShadow>
            </Grid>

            <Grid item xs={12} md={8} lg={8} xl={8}>
              <BoxShadow>
                <Stack minHeight={BOX_HEIGHT} padding={3}>

                  <Stack display='flex' flex={1}>
                    <Box>
                      <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={8} sm={12}>
                          <FormikTextField name='clientName' fullWidth label='Client name' disabled />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextNumericFormat name='amount' label='Amount' />
                        </Grid>

                        <Grid item xs={12}>
                          <FormikTextField name='observation' fullWidth label='Observations' rows={5} multiline />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack flexDirection='row' justifyContent='flex-end' >
                    <Button
                      color='btnSecondary'
                      variant='contained'
                      disableElevation
                      onClick={() => { navigate(RoutesNames.ORDER_CREDIT_NOTE) }}
                    >
                      Back
                    </Button>
                    <Box mr={2} />
                    <LoadingButton
                      variant='contained'
                      color='btnPrimaryFill'
                      disableElevation
                      type='submit'
                      loading={loadingSave}
                      disabled={creditSelected?.isUsed}
                    >
                      {creditSelected ? 'Save update' : 'Save part'}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </BoxShadow>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

    </Box >
  )
}

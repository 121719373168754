import React from 'react'

import moment from 'moment'

import { EnumServiceStatus } from '@/constants/service'
import { useGlobalState } from '@/context'
import { Avatar, Grid, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'

export interface PrintServiceLoteProps {
  serviceList: Service[]
}

export const PrintServiceLote = React.forwardRef<HTMLDivElement, PrintServiceLoteProps>(
  (props, ref) => {
    const [{ company }] = useGlobalState()
    const { serviceList } = props

    return (
      <div ref={ref}>
        <Stack padding={2}>
          <Stack flexDirection='row' alignItems='center'>
            <Avatar
              src={company?.photoURL}
              variant='rounded'
              sx={{
                height: 120,
                width: 120,
              }}
            />

            <Stack display='flex' flex={1} justifyContent='center' pl={2}>
              <Typography variant='h6'>{company?.name}</Typography>
              <Typography variant='body1'>E-mail: {company?.email}</Typography>
              <Typography variant='body1'>
                Phone Number: {company?.phoneNumber}
              </Typography>
              <Typography variant='body1'>
                Address: {company?.address}
              </Typography>
              <Typography variant='body1'>
                Website: {company?.websiteUrl}
              </Typography>
            </Stack>
          </Stack>

          <Box mb={6} />

          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={1}>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>KEY</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>NAME</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>CLIENT NAME</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>CREATED DT</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='body1' sx={{ fontWeight: 500 }}>FLOW</Typography>
            </Grid>
          </Grid>

          {serviceList.map((service, idx) => (
            <Stack key={service.id}>
              <Grid container spacing={0} sx={{ py: 1, borderBottom: `1px solid ${grey[200]}` }}>
                <Grid item xs={1}>
                  <Typography variant='caption'>{service.id.substring(0, 6).toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Stack width={240}>
                    <Typography variant='caption' textOverflow='ellipsis' noWrap>{service.title}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack width={180}>
                    <Typography variant='caption' textOverflow='ellipsis' noWrap>{service.clientFullname}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='caption'>
                    {moment(service.createdAt || '').format('DD/MM/YYYY, hh:mm')}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='caption'>
                    {(EnumServiceStatus[service.status] || '').toLocaleLowerCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          ))}
        </Stack>
      </div>
    )
  },
)
PrintServiceLote.displayName = 'PrintPageLote'

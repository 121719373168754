import React from 'react'

import { Box, circularProgressClasses } from '@mui/material'
import CircularProgressUi from '@mui/material/CircularProgress'

interface CircularProgressProps {
    size?: number
    thickness?: number
}
export const CircularProgress = ({ size = 24, thickness = 4 }: CircularProgressProps) => (
    <Box sx={{ position: 'relative', mt: 0.5 }}>
        <CircularProgressUi
            variant="determinate"
            sx={(theme) => ({
                color: theme.palette.grey[200],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[800],
                }),
            })}
            size={size}
            thickness={thickness}
            value={100}
        />
        <CircularProgressUi
            variant="indeterminate"
            disableShrink
            sx={(theme) => ({
                color: theme.palette.primary.main,
                animationDuration: '550ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                ...theme.applyStyles('dark', {
                    color: theme.palette.primary.main,
                }),
            })}
            size={size}
            thickness={thickness}
        />
    </Box>
)

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { TbDotsVertical, TbPencil, TbPlus, TbTrash } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import {
  AvatarLetter,
  BoxShadow,
  Breadcrumb,
  CircularProgress,
  DialogConfirm,
  Status,
} from '@/components'
import { NoContent } from '@/components/no-content'
import { RoutesNames } from '@/router/routes'
import { getList } from '@/services/api/company'
import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

const BREADCRUM_MENU: Breadcrumb[] = [
  {
    id: 'Dashboard',
    title: 'Dashboard',
    href: '/',
  },
  {
    id: 'User',
    title: 'User',
    isCurrentPage: true,
  },
]
const BOX_HEIGHT = 420

export const User = () => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const [userSelected, setUserSelected] = useState<User | undefined>(undefined)
  const open = Boolean(anchorEl)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false)

  const [userList, setUserList] = useState<User[] | undefined>(undefined)
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)

  const handleMenuAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: User,
  ) => {
    setAnchorEl(event.currentTarget)
    setUserSelected(user)
  }

  const handleCloseMenuAction = () => {
    setAnchorEl(undefined)
  }

  const handleDeleteUser = async () => {
    if (!userSelected) return
    setLoadingDelete(true)
    // await ApiService.Company.remove({ companyId: userSelected.id })
    //   .then(async () => {
    //     if (userSelected.photoURL) {
    //       await ApiService.Company.removePhoto({
    //         companyId: userSelected.id,
    //         photoName: userSelected.photoName
    //       })
    //     }
    //     setIsOpenConfirmDelete(false)
    //     dispatch({
    //       type: 'SET_SNACKBAR',
    //       data: { message: 'Company deleted' }
    //     })
    //   }).catch(() => {
    //     setIsOpenConfirmDelete(false)
    //     dispatch({
    //       type: 'SET_SNACKBAR',
    //       data: { message: 'Company was not deleted' }
    //     })
    //   }).finally(() => {
    //     setLoadingDelete(false)
    //   })
  }

  const renderTableBody = () => {
    if (loadingList) {
      return (
        <Stack
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <CircularProgress />
        </Stack>
      )
    }

    if (userList?.length === 0) {
      return (
        <Stack
          display='flex'
          alignItems='center'
          width='100%'
          position='absolute'
          height='360px'
        >
          <NoContent />
        </Stack>
      )
    }

    return (
      <TableBody>
        {userList?.map(user => (
          <TableRow hover key={user.uid}>
            <TableCell>
              <Stack flexDirection='row'>
                {user.userConfig?.photoURL ? (
                  <Avatar src={user.userConfig?.photoURL} />
                ) : (
                  <AvatarLetter
                    name={(user?.displayName || '')
                      .substring(0, 1)
                      .toUpperCase()}
                  />
                )}
                <Box marginRight={2} />
                <Stack justifyContent='center'>
                  <Typography variant='body2'>{user?.displayName}</Typography>
                  <Typography variant='caption' color={grey[500]}>
                    {user.email}
                  </Typography>
                </Stack>
              </Stack>
            </TableCell>

            <TableCell>
              <Stack flexDirection='row'>
                <Status
                  title={user ? 'Active' : 'Inactive'}
                  type={user ? 'SUCCESS' : 'ERROR'}
                />
              </Stack>
            </TableCell>
            <TableCell>
              <Stack flexDirection='row'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      navigate(RoutesNames.USER_CRUD, {
                        state: { userSelected: user },
                      })
                    }}
                  >
                    <TbPencil color={grey[900]} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Options'>
                  <IconButton
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => {
                      handleMenuAction(event, user)
                    }}
                  >
                    <TbDotsVertical color={grey[900]} />
                  </IconButton>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  useEffect(() => {
    if (userList !== undefined) return
    console.log('CARREGAR')

    // getList({ setUserList, setLoadingList })
  }, [userList, getList])

  return (
    <Box>
      <Breadcrumb pageTitle='List of companies' menu={BREADCRUM_MENU}>
        <Stack display='flex' alignItems='center'>
          <Button
            startIcon={<TbPlus />}
            color='btnSecondary'
            variant='contained'
            size='small'
            onClick={() => {
              navigate(RoutesNames.USER_CRUD)
            }}
            disableElevation
          >
            New
          </Button>
        </Stack>
      </Breadcrumb>

      <BoxShadow>
        <TableContainer sx={{ height: BOX_HEIGHT }}>
          {/* <Stack
          padding={2}
          sx={{ width: { xs: '100%', md: '50%', lg: '50%', xl: '50%' } }}
        >
          <TextField
            size='small'
            label='Pesquisar por nome'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <TbSearch color={grey[900]} />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Stack> */}
          <Table stickyHeader sx={{ position: 'relative' }} size='small'>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </TableCell>
                <TableCell width={100}>
                  <Typography variant='subtitle2'>Status</Typography>
                </TableCell>
                <TableCell width={50} />
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
      </BoxShadow>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenuAction}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            navigate(RoutesNames.USER_CRUD, {
              state: { userSelected },
            })
          }}
        >
          <ListItemIcon>
            <TbPencil size={20} color={grey[900]} />
          </ListItemIcon>
          <Typography variant='body2'>Edit</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenuAction()
            setIsOpenConfirmDelete(true)
          }}
        >
          <ListItemIcon>
            <TbTrash size={20} color={red[500]} />
          </ListItemIcon>
          <Typography variant='body2' color={red[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      {userSelected && (
        <DialogConfirm
          title='Delete'
          description={`Do you really want to delete "${userSelected.displayName}"?`}
          open={isOpenConfirmDelete}
          onCloseDialog={() => {
            setIsOpenConfirmDelete(false)
          }}
          btnOkProps={{
            color: 'error',
            onClick: () => {
              handleDeleteUser()
            },
            loading: loadingDelete,
          }}
          btnNoProps={{
            variant: 'outlined',
            onClick: () => {
              setIsOpenConfirmDelete(false)
            },
          }}
        />
      )}
    </Box>
  )
}
